import { OrderStatus, PriceOffer } from '../../../services/api/order/OrderService.types'
import { environmentConfig } from '../../../config/environment/environmentConfig'
import { formatDate } from '../../../utils/DateHelper'
import AppBarTitle from './AppBarTitle'
import AppBarUserMenu from './AppBarUserMenu'
import HamburgerIcon from '../../base/icon/Hamburger'
import React, { PropsWithChildren, ReactNode } from 'react'
import useTheme from '../../../hooks/useTheme'

export type AppBarProps = {
    title?: string | ReactNode
    showUserMenu?: boolean
    isNavBar?: boolean
    showDate?: boolean
    showButtonPriceOffer?: boolean
    demandStatus?: OrderStatus
    priceOffer?: PriceOffer[]
}

const AppBar = ({
    children,
    showUserMenu = true,
    isNavBar = false,
    showDate = true,
    title,
    demandStatus,
    priceOffer
}: PropsWithChildren<AppBarProps>) => {
    const date = formatDate(new Date(), String(environmentConfig.dateFormat))
    const { setOpenedSidebar, openedSidebar } = useTheme()

    const openMobileSidebar = () => {
        return setOpenedSidebar(prevState => {
            return !prevState
        })
    }

    const renderDate = () => {
        if (showDate) {
            return <span className='text-body-small font-normal hidden lg:block color-[#1A202C]'>{date}</span>
        }
    }

    return (
        <>
            <div
                className={`flex flex-1 justify-between py-4 ${!openedSidebar ? 'pr-4 pl-7' : 'px-7'}
            ${!isNavBar ? 'shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)]' : ''}
            `}
            >
                <div className='flex items-center gap-6'>
                    <button onClick={openMobileSidebar}>
                        <HamburgerIcon aria-hidden='true' fill='black' />
                    </button>
                    <AppBarTitle title={title} demandStatus={demandStatus} priceOffer={priceOffer} />
                </div>
                <div className='flex items-center gap-4'>
                    {children}
                    {renderDate()}
                    {showUserMenu && <AppBarUserMenu />}
                </div>
            </div>
            {isNavBar && <div className='border-b-2 mx-4' />}
        </>
    )
}

export default AppBar
