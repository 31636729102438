import { CalculatorIcon } from '@heroicons/react/outline'
import { OrderStatus, PriceOffer } from '../../services/api/order/OrderService.types'
import { SidebarItem } from '../dashboard/sidebar/Sidebar'
import { buildClassesWithDefault } from '../../utils/StyleHelper'
import { useTranslation } from 'next-i18next'
import AppBar, { AppBarProps } from '../dashboard/app-bar/AppBar'
import Button from '../base/button/Button'
import Change from '../base/icon/ChangeIcon'
import Column from '../base/grid/Column'
import Complaint from '../base/icon/ComplaintIcon'
import Demand from '../base/icon/DemandIcon'
import Download from '../base/icon/DownloadIcon'
import Home from '../base/icon/HomeIcon'
import Invoice from '../base/icon/InvoiceIcon'
import Layout, { LayoutProps } from './Layout'
import Link from 'next/link'
import Loading from '../base/loading/Loading'
import MobileSidebar from '../dashboard/sidebar/MobileSidebar'
import Order from '../base/icon/OrderIcon'
import PostInfoCardList from '../dashboard/posts/PostInfoCardList'
import React, { PropsWithChildren, ReactNode, useMemo } from 'react'
import WrapperContent, { WrapperType } from '../base/wrapper-content'
import useMiddleware from '../../hooks/useMiddleware'
import useTheme from '../../hooks/useTheme'

export type PrimaryTitle = 'appbar' | 'layout'

export type DashboardLayoutProps = LayoutProps & {
    dashboardTitle?: ReactNode
    appBarContent?: ReactNode
    navigationLine?: ReactNode
    appBarProps?: AppBarProps
    primaryTitle?: PrimaryTitle
    showAppBar?: boolean
    showPosts?: boolean
    sidebar?: ReactNode
    footer?: JSX.Element
    demandStatus?: OrderStatus
    priceOffer?: PriceOffer[]
    type?: WrapperType
}

const DashboardLayout = ({
    children,
    dashboardTitle,
    appBarContent,
    navigationLine,
    appBarProps,
    showAppBar = true,
    showPosts = true,
    primaryTitle = 'appbar',
    sidebar,
    footer,
    demandStatus,
    priceOffer,
    type,
    ...props
}: PropsWithChildren<DashboardLayoutProps>) => {
    const { t } = useTranslation()
    const { openedSidebar } = useTheme()
    const { isInitialized } = useMiddleware(true)

    const navigation = useMemo<SidebarItem[]>(() => {
        return [
            {
                name: t('frontend.sidebar.items.home'),
                href: '/app',
                icon: Home
            },
            {
                name: t('frontend.sidebar.items.demands'),
                href: '/app/demand',
                icon: Demand
            },
            {
                name: t('frontend.sidebar.items.orders'),
                href: '/app/order',
                icon: Order
            },
            {
                name: t('frontend.sidebar.items.price_offers'),
                href: '/app/price-offers',
                icon: Invoice
            },
            {
                show: false,
                name: t('frontend.sidebar.items.mpvz_calculator'),
                href: '/app/mpvz-calculator',
                icon: CalculatorIcon
            },
            {
                name: t('frontend.sidebar.items.complaints_and_repairs'),
                href: '/app/complaints-and-repairs',
                icon: Complaint
            },
            {
                name: t('frontend.sidebar.items.assortment_updates'),
                href: '/app/assortment-updates',
                icon: Change
            },
            {
                name: t('frontend.sidebar.items.download'),
                href: '/app/download',
                icon: Download
            }
        ]
    }, [t])

    const renderAppBar = () => {
        const title = primaryTitle === 'appbar' ? dashboardTitle || props.title : ' '
        if (showAppBar) {
            return (
                <div className='sticky top-0 w-full bg-white z-10'>
                    <AppBar
                        title={title}
                        demandStatus={demandStatus}
                        priceOffer={priceOffer}
                        isNavBar={!!navigationLine}
                        {...appBarProps}
                    >
                        {appBarContent}
                    </AppBar>
                    {navigationLine}
                </div>
            )
        }
        return null
    }

    const renderContent = () => {
        return (
            <>
                <main>
                    <Column removePaddingX removePaddingY>
                        {renderAppBar()}
                        <div className='flex p-8 bg-cover bg-center gap-4'>
                            <div className={`${showPosts ? 'basis-3/4' : 'w-full'}`}>
                                <WrapperContent type={type}>{children}</WrapperContent>
                            </div>
                            {showPosts && (
                                <div className='basis-1/4'>
                                    <aside className='sticky top-0 h-[100vh] overflow-y-auto no-scrollbar'>
                                        <PostInfoCardList />
                                        <div className='sticky bottom-12  z-20 flex justify-center py-4'>
                                            <div className='flex xl:h-[40px] xl:mb-[-15px] xl:w-full xl:blur'></div>
                                            <Button
                                                as={Link}
                                                href='/app/news'
                                                // variant='destructive'
                                                className='xl:fixed xl:bottom-4'
                                            >
                                                {t('frontend.dashboard.demand.table.actions.show_all')}
                                            </Button>
                                        </div>
                                    </aside>
                                </div>
                            )}
                        </div>
                    </Column>
                </main>
            </>
        )
    }

    const renderLoading = () => {
        if (!isInitialized) {
            return (
                <div className='w-full min-h-screen flex justify-center items-center'>
                    <Loading className='w-24 h-24' />
                </div>
            )
        }
    }

    return (
        <Layout {...props}>
            {isInitialized && (
                <div>
                    <MobileSidebar items={navigation}>{sidebar}</MobileSidebar>
                    <div
                        className={buildClassesWithDefault(
                            {
                                'md:pl-[256px]': openedSidebar
                            },
                            'flex flex-col justify-between min-h-screen transition-all duration-300'
                        )}
                    >
                        {renderContent()}
                        {footer}
                    </div>
                </div>
            )}
            {renderLoading()}
        </Layout>
    )
}

export default DashboardLayout
