/* eslint-disable max-len */
export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'disabled' | 'destructive' | 'inverse' | 'icon'

export type ButtonType = 'normal' | 'text'
export type ButtonSize = 'tiny' | 'sm' | 'md' | 'lg' | 'icon' | 'tiny-icon'

type ButtonConfig = {
    sizes: {
        button: Record<ButtonSize, string>
        icon: Record<ButtonSize, string>
    }
    variant: Record<ButtonVariant, Record<ButtonType, string>>
}

export const buttonConfig: ButtonConfig = {
    sizes: {
        button: {
            tiny: 'px-4 text-xs font-normal leading-6',
            'tiny-icon': 'px-1 text-xs font-normal leading-6',
            sm: 'text-xs font-normal leading-8',
            md: 'py-[10px] px-[14px] text-sm font-normal',
            lg: 'py-[10px] px-[14px] text-base font-normal',
            icon: 'font-normal'
        },
        icon: {
            tiny: 'h-2 w-2',
            'tiny-icon': 'h-6 w-4',
            sm: 'h-4 w-4',
            md: 'h-4 w-4',
            lg: 'h-4 w-4',
            icon: 'h-7 w-7'
        }
    },
    variant: {
        primary: {
            normal: 'bg-primary text-white border-none transition-all duration-200 ease justify-center items-center shadow-primary-shadow rounded-[10px] hover:shadow-primary-shadow-hover active:shadow-primary-shadow-active active:transform active:translate-x-[2px] active:translate-y-[2px]',
            text: 'text-primary border border-transparent hover:text-primary-200'
        },
        secondary: {
            normal: 'bg-gray text-white border-none transition-all duration-200 ease justify-center items-center shadow-primary-shadow rounded-[10px] hover:shadow-primary-shadow-hover active:shadow-primary-shadow-active active:transform active:translate-x-[2px] active:translate-y-[2px]',
            text: 'text-secondary border border-transparent hover:text-secondary-200'
        },
        destructive: {
            normal: 'bg-white text-primary border border-primary rounded-[10px]',
            text: 'text-primary border border-transparent hover:text-primary-200'
        },
        tertiary: {
            normal: 'bg-none text-secondary border-none transition-all duration-200 ease justify-center items-center shadow-primary-shadow rounded-[10px] hover:shadow-primary-shadow-hover active:shadow-primary-shadow-active active:transform active:translate-x-[2px] active:translate-y-[2px]',
            text: 'text-secondary border border-transparent hover:text-secondary-200'
        },
        disabled: {
            normal: 'bg-gray-200 text-gray-450 border border-gray-200 rounded-[10px] cursor-not-allowed',
            text: ''
        },
        inverse: {
            normal: 'bg-inherit rounded-md text-white border border-white',
            text: ''
        },
        icon: {
            normal: 'flex items-center h-full p-1',
            text: ''
        }
    }
}
