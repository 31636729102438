import { SidebarProps } from './Sidebar'
import { Transition } from '@headlessui/react'
import { useSideBarButton } from '../../../hooks/useSideBarButton'
import React, { PropsWithChildren } from 'react'
import SidebarFooter from './SidebarFooter'
import SidebarHeader from './SidebarHeader'
import SidebarList from './SidebarList'
import useTheme from '../../../hooks/useTheme'

export type MobileSidebarProps = SidebarProps & {}

const MobileSidebar = ({ children, items }: PropsWithChildren<MobileSidebarProps>) => {
    const { openedSidebar: isOpened } = useTheme()
    const { toggleButton } = useSideBarButton()

    const renderContent = () => {
        return children ? children : <SidebarList items={items} />
    }

    return (
        <>
            <Transition
                show={isOpened}
                as='div'
                className='sidebar fixed bg-white inset-0 flex md:w-[16rem]'
                enter='transition ease-in-out duration-300 transform'
                enterFrom='-translate-x-full'
                enterTo='translate-x-0'
                leave='transition ease-in-out duration-300 transform'
                leaveFrom='translate-x-0'
                leaveTo='-translate-x-full'
                afterLeave={() => {
                    toggleButton()
                }}
                beforeEnter={() => {
                    toggleButton()
                }}
            >
                <div className='relative flex-1 flex flex-col max-w-[256px] w-full border-r-2 pb-4'>
                    <SidebarHeader />
                    {renderContent()}
                    <SidebarFooter />
                </div>
            </Transition>
        </>
    )
}

export default MobileSidebar
