import { HTMLAttributes, PropsWithChildren } from 'react'
import { buildClassesWithDefault } from '../../../utils/StyleHelper'

export type WrapperType = 'table' | 'dashboard' | 'default'

type WrapperContentProps = Omit<HTMLAttributes<HTMLDivElement>, 'color'> & {
    type?: WrapperType
}

const WrapperContent = ({ children, className, type = 'default' }: PropsWithChildren<WrapperContentProps>) => {
    const getWidth = () => {
        switch (type) {
            case 'table':
                return 'max-w-full'
            case 'default':
                return 'max-w-7xl'
        }
    }

    return (
        <div
            className={buildClassesWithDefault(
                'flex flex-col gap-6 justify-center align-middle w-full mx-auto',
                getWidth(),
                className
            )}
        >
            {children}
        </div>
    )
}

export default WrapperContent
